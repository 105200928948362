import React from 'react';

import styles from './Loader.module.scss';

interface LoaderProps {
  isLoading: boolean;
  bgColour?: string;
}

const Loader: React.FC<LoaderProps> = ({ children, isLoading }) => {
  if (!isLoading) {
    return <>{children}</>;
  }
  return (
    <div className={styles.loader} />
  );
};

export default Loader;