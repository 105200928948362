import React from 'react';

import { Align } from 'types/enums';

import { arrowDownIcon } from 'assets/icons';
import styles from './SectionNudge.module.scss';

interface SectionNudgeProps {
  className?: string;
  text: string;
  alignment?: Align;
}

const SectionNudge: React.FC<SectionNudgeProps> = ({ className, text, alignment = Align.Left }) => {
  return (
    <div className={`${className ? className : ''} ${alignment === Align.Right ? `${styles.sectionNudge} right` : styles.sectionNudge}`}>
      <img
        className={styles.iconDown}
        src={arrowDownIcon}
        alt={text} />
      <h2>{text}</h2>
    </div>
  );
};

export default SectionNudge;