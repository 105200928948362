import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

import {
  graphic01Scene,
  graphic01Text,
  graphic01Person01,
  graphic01Person02
} from 'assets/images';

import styles from './ParallaxImage.module.scss';

const ParallaxImage: React.FC = () => {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: {
      mass: 10,
      tension: 550,
      friction: 140
    }
  }));

  const trans1 = (x: number, y: number) => `translate3d(${x / 40}px,${y / 40}px,0)`;
  const trans2 = (x: number, y: number) => `translate3d(${x / 30}px,${y / 30}px,0)`;
  const trans3 = (x: number, y: number) => `translate3d(${x / 23}px,${y / 23}px,0)`;
  const trans4 = (x: number, y: number) => `translate3d(${x / 25}px,${y / 25}px,0)`;

  const calc = (x: number, y: number) => [x - window.innerWidth / 2, y - window.innerHeight / 2];

  useEffect(() => {
    document.addEventListener('mousemove', ({ clientX: x, clientY: y }) => set({ xy: calc(x, y) }));
  }, []);

  return (
    <div
      className={styles.parallaxContainer}>
      <div className={styles.innerContainer}>
        <animated.img
          className={styles.graphicScene}
          src={graphic01Scene}
          style={{ transform: props.xy.to(trans1) }} />
        <animated.img
          className={styles.graphicText}
          src={graphic01Text}
          style={{ transform: props.xy.to(trans2) }} />
        <animated.img
          className={styles.graphicPerson01}
          src={graphic01Person01}
          style={{ transform: props.xy.to(trans3) }} />
        <animated.img
          className={styles.graphicPerson02}
          src={graphic01Person02}
          style={{ transform: props.xy.to(trans4) }} />
      </div>
    </div>
  );
};

export default ParallaxImage;