import React from 'react';

import { Header, Footer, Section, SectionNudge, HeaderBlurb } from 'components';

import { apiSetup01, apiSetup02, apiSetup03 } from 'assets/images';
import { Align, Span } from 'types/enums';
import styles from './ApiSetup.module.scss';

const ApiSetup: React.FC = () => {
  return (
    <>
      <Header
        alignment={Align.Center}
        topBarButtonText='Try free today'>
        <h1>How to <strong>take advantage</strong> of your existing tech using our API</h1>

        <HeaderBlurb className='left'>
          Our product is built API-first with developers in mind. The API makes it easy to connect with your existing technology both simplifying your business process and extending your capabilities.
        </HeaderBlurb>

        <SectionNudge text='Get integrated' />
      </Header>

      <main>
        <div className={styles.row}>
          <div className={styles.column50}>
            <div className={styles.content}>
              <h3>Step 1</h3>
              <h2>Get integration details</h2>
              <p>Open the Integrations settings page and select your API application.</p>
              <p>Here you will find and the details you need to integrate your existing system into our API.</p>
            </div>
          </div>

          <div className={`${styles.column50} ${styles.image}`}>
            <img
              src={apiSetup01}
              className={`${styles.stepImage} ${styles.apiDetails}`}
              alt='Retrieve your Application&apos;s connection details' />
          </div>

          <SectionNudge
            text='Setup request payload'
            alignment={Align.Right} />
        </div>

        <div className={styles.row}>
          <div className={`${styles.column50} ${styles.image}`}>
            <img
              src={apiSetup02}
              className={styles.stepImage}
              alt='Setup an application to configure your integration.' />
          </div>

          <div className={styles.column50}>
            <div className={styles.content}>
              <h3>Step 2</h3>
              <h2>Setup request payload</h2>
              <p>The payload is made up of three core parts. The application details, template id and the template data.</p>
              <p>With this we can link the transactions to your application allowing full transparency of both the connection health and billing.</p>
            </div>
          </div>

          <SectionNudge text='You&apos;re ready!' />
        </div>

        <Section
          alignment={Align.Center}
          span={Span.Full}>
          <div className={`${styles.content} ${styles.lastStep} center`}>
            <h3>Step 3</h3>
            <h2>Send us the request</h2>

            <img
              src={apiSetup03}
              alt='Send your document out for user completion.' />
          </div>
        </Section>
      </main>

      <Footer />
    </>
  );
};

export default ApiSetup;