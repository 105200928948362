import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { IoLogoLinkedin, IoLogoInstagram } from 'react-icons/io5';

import { Form, Icon, Loader, CustomLink } from 'components';

import { linkIcon } from 'assets/icons';

import {
  logoDark,
  whtspcLogo
} from 'assets/images';

import styles from './Footer.module.scss';

interface SubFormData {
  email: string;
}

const Footer: React.FC = () => {
  const handleValidate = (formData: SubFormData) => !!formData.email;

  // Submit subscriber form
  const handleSubscribe = async (formData: SubFormData) =>
    await axios({
      method: 'post',
      url: `https://${process.env.REACT_APP_BASE_URL}/subscribe.php`,
      headers: { 'content-type': 'application/json' },
      data: formData
    });

  return (
    <footer className='center'>
      <h2 className='primary'>Get started now for <span>free</span>, <br />or <span>request a demo</span> to see how <br />it can help you</h2>

      <div className={styles.buttonGroup}>
        <a type='primary' href='https://app.whtdoc.com.au/' className={styles.signup}>Get started</a>
        <CustomLink type='secondary' label='Request demo' link='/contact?reason=demo' />
      </div>

      {/* TODO: update links */}
      <div className={styles.footerContainer}>
        <div className={styles.footerNavigation}>
          <div className={styles.column}>
            <h3>Information</h3>
            <ul>
              <li>
                <Link to='/' className='link'>Home</Link>
              </li>
              <li>
                <Link to='/pricing' className='link'>Pricing guide</Link>
              </li>
              <li>
                <Link to='/getting-started' className='link'>Getting started</Link>
              </li>
              <li>
                <Link to='/api-setup' className='link'>API setup</Link>
              </li>
              <li>
                <Link to='/contact' className='link'>Get in touch</Link>
              </li>
              <li>
                <a href='https://app.whtdoc.com.au/' className='link'>Sign up</a>
              </li>
            </ul>
          </div>

          <div className={styles.column}>
            <h3>Product</h3>
            <ul>
              <li>
                <a href='#' className='link'>Login</a>
              </li>
            </ul>
          </div>

          <div className={styles.column}>
            <h3>Legal</h3>
            <ul>
              <li>
                <Link to='/terms' className='link'>Terms &amp; conditions</Link>
              </li>
              <li>
                <Link to='/privacy' className='link'>Privacy policy</Link>
              </li>
            </ul>
          </div>

          <div className={styles.column}>
            <h3>Social</h3>
            <ul>
              <li>
                <a className='link' href='//www.linkedin.com/company/whtspcstudio/' target='_blank' rel='noreferrer'>LinkedIn</a>
              </li>
              <li>
                <a className='link' href='//www.instagram.com/whtspc_studio/' target='_blank' rel='noreferrer'>Instagram</a>
              </li>
            </ul>
          </div>

        </div>
        <Form<SubFormData>
          onValidate={handleValidate}
          onSubmitAsync={handleSubscribe}
          className={styles.subscribeForm}>
          {({ formData, formSubmitted, formError, formLoading, setFieldValue }) =>
            <>
              <h3>Subscribe for product updates</h3>

              <Loader isLoading={formLoading}>
                <div className={styles.formContainer}>
                  <input
                    required
                    type='email'
                    readOnly={formSubmitted}
                    placeholder='Enter your email address'
                    value={formData.email ?? ''}
                    className={formError ? styles.error : undefined}
                    onChange={e => setFieldValue('email', e.target.value)} />

                  <button
                    disabled={formSubmitted || formLoading}
                    type='submit'>Sign up <Icon img={linkIcon} /></button>
                </div>
              </Loader>
            </>
          }
        </Form>
      </div>

      {/* TODO: update social links */}
      <div className={styles.footnote}>
        <Link to='/'>
          <img
            src={logoDark}
            alt='whtdoc'
            className={styles.logo} />
        </Link>

        <span className={styles.copy}><span>built by&nbsp;</span>
          <a
            href='//whtspc.com.au/'
            target='_blank'
            rel='noreferrer'>
            <img
              alt='whtspc'
              src={whtspcLogo} />
          </a>
        </span>

        <ul className={styles.socialList}>
          <li>
            <a href='//www.instagram.com/whtspc_studio/' target='_blank' rel='noreferrer'>
              <IoLogoInstagram />
            </a>
          </li>
          <li>
            <a href='//www.linkedin.com/company/whtspcstudio/' target='_blank' rel='noreferrer'>
              <IoLogoLinkedin />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;