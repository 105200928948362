import React from 'react';

import { Header, Footer, ContactForm } from 'components';
import { Align } from 'types/enums';

const Contact: React.FC = () => {
  return (
    <>
      <Header
        alignment={Align.Center}
        topBarButtonText='Try free today'>
        <h1>Get in touch with us and <strong>let us know</strong> what you think</h1>

        <ContactForm />
      </Header>
      <Footer />
    </>
  );
};

export default Contact;