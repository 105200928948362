import React from 'react';

import styles from './IconBlock.module.scss';

interface IconBlockProps {
  title: string;
  subTitle?: string;
  img: string;
}

const IconBlock: React.FC<IconBlockProps> = ({ children, title, subTitle, img }) => {
  return (
    <div className={styles.iconBlock}>
      <img
        src={img}
        alt={title} />
      <h3>{title}</h3>
      {subTitle &&
        <h4>{subTitle}</h4>
      }
      {children}
    </div>
  );
};

export default IconBlock;