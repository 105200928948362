import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoPaperPlaneOutline } from 'react-icons/io5';

import {
  Header,
  Footer,
  Section,
  IconBlock,
  CustomLink,
  ParallaxImage
} from 'components';

import {
  arrowIcon,
  priceIcon,
  modularIcon,
  scaleIcon,
  secureIcon
} from 'assets/icons';

import {
  graphic02,
  flowSimple01,
  flowSimple02,
  flowSimple03,
  apiSetup03,
  flowApi01
} from 'assets/images';

import styles from './Home.module.scss';
import { Align, Span } from 'types/enums';

const Home: React.FC = () => {
  const [showBasic, setShowBasic] = useState(true);

  const toggleShowBasic = () => setShowBasic(!showBasic);

  const renderFlowBlock = (text: string, image: string, extraStyles?: any) => {
    return (
      <div className={styles.flowBlock}>
        <img
          style={extraStyles}
          src={image}
          alt={text} />

        <h3>{text}</h3>
      </div>
    );
  };

  return (
    <>
      <Header
        span={Span.Split}
        topBarButtonText='Login'>
        <div className={`${styles.headerBackground} sectionBackground`}></div>
        <h1>Say goodbye to<br /> <span>creating</span> documents<br /> the old way</h1>
        <p>Seamlessly plug our API-first platform into your business and start creating beautiful documents today.</p>

        <div className={styles.buttonGroup}>
          <Link to='/getting-started' className='alt'>Find out how?</Link>
          <a className={styles.signUp} type='primary' href='https://app.whtdoc.com.au/'>Sign up today</a>
        </div>

        <ParallaxImage />
      </Header>

      <main>
        <Section
          className={styles.integrationSection}
          alignment={Align.Right}
          span={Span.Split}>
          <div className={`${styles.integrationBackground} sectionBackground`}></div>

          <div className={styles.integrationContent}>
            <h2 className='primary'>Designed to be <strong>flexible</strong><br /> and <strong>grow</strong> with your<br /> business</h2>
            <p>Leverage our modular document creation service that moulds to fit your business, be it small, medium or enterprise.</p>
            <CustomLink type='primary' label='Get more info' link='/getting-started' icon={<IoPaperPlaneOutline />} />
          </div>

          <img
            src={graphic02}
            className={styles.imgLarge} />
        </Section>

        <Section
          className={styles.productBenefits}
          alignment={Align.Left}
          span={Span.Full}>
          <h2 className='primary'>A modern approach to creating documents, helping run your business more <strong>effectively</strong></h2>

          <ul className='iconsList'>
            <li>
              <IconBlock
                title='Get what you pay for'
                img={priceIcon}>
                <p>Designed to generate your documents and do it well. You won&apos;t be paying for features you don&apos;t need.</p>
              </IconBlock>
            </li>
            <li>
              <IconBlock
                title='Plug and play'
                img={modularIcon}>
                <p>Our solution slots into your business and technology stack as is, this flexibility allows you to configure the platform to meet your changing needs.</p>
              </IconBlock>
            </li>
            <li>
              <IconBlock
                title='Scales with your business'
                img={scaleIcon}>
                <p>A platform you can depend on to deliver documents reliably, quickly and at scale. Powered by the cloud allowing us to be there when you need it most.</p>
              </IconBlock>
            </li>
            <li>
              <IconBlock
                title='Your data belongs to you'
                img={secureIcon}>
                <p>We don&apos;t need your data, any information collected with your documents is transient. Once we&apos;ve sent it to you our job is done, we won&apos;t be holding onto it.</p>
              </IconBlock>
            </li>
          </ul>
        </Section>

        <Section
          alignment={Align.Center}
          span={Span.Full}>
          <div className={styles.largeButtonGroup}>
            <button
              className={showBasic ? 'large active' : 'large blurred'}
              onClick={toggleShowBasic}>Basic</button>
            <button
              className={!showBasic ? 'large active' : 'large blurred'}
              onClick={toggleShowBasic}>Developer</button>
          </div>
          {showBasic ?
            <>
              <h2 className='primary'>The <strong>simplest</strong> way to send forms and create documents</h2>

              <div className={`${styles.flow}`}>
                {renderFlowBlock('Design your document template', flowSimple01, { transform: 'translateX(-5%) scale(1.1)' })}

                <img
                  className={styles.small}
                  src={arrowIcon} />

                {renderFlowBlock('Send form to your customers', flowSimple02)}

                <img
                  className={styles.small}
                  src={arrowIcon} />

                <div className={styles.flowBlock}>
                  <img
                    src={flowSimple03}
                    alt='Receive completed documents' />

                  <h3>Receive completed documents</h3>
                </div>
              </div>

              <Link to='/getting-started' className='primary mb-100'>See how easy it is today</Link>
            </>
            :
            <>
              <h2 className='primary'>The <strong>cheapest</strong> way to generate documents</h2>

              <div className={`${styles.flow}`}>
                {renderFlowBlock('Design your document template', flowSimple01, { transform: 'translateX(-5%) scale(1.1)' })}

                <img
                  className={styles.small}
                  src={arrowIcon} />

                {renderFlowBlock('Configure your integration', flowApi01)}

                <img
                  className={styles.small}
                  src={arrowIcon} />

                {renderFlowBlock('Invoke our RESTful API', apiSetup03)}

                <img
                  className={styles.small}
                  src={arrowIcon} />

                <div className={styles.flowBlock}>
                  <img
                    src={flowSimple03}
                    alt='Receive completed documents' />

                  <h3>Receive completed documents</h3>
                </div>
              </div>

              <Link to='/api-setup' className='primary'>See how easy it is today</Link>
            </>
          }
        </Section>
      </main>
      <Footer />
    </>
  );
};

export default Home;