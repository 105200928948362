import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import GA4React from 'ga-4-react';

import {
  Home,
  GettingStarted,
  ApiSetup,
  Pricing,
  Contact,
  NotFound,
  Privacy,
  Terms
} from 'pages';

import { ScrollReset } from 'components';
import { useEffect } from 'react';

const App: React.FC = () => {
  useEffect(() => {
    try {
      // Initialize Google Analytics
      const ga4react = new GA4React(process.env.REACT_APP_ANALYTICS_ID || '');
      ga4react.initialize();
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error('Google Analytics Failure', error);
    }
  }, []);

  return (
    <Router>
      <>
        <ScrollReset />
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>

          <Route exact path='/getting-started'>
            <GettingStarted />
          </Route>

          <Route exact path='/api-setup'>
            <ApiSetup />
          </Route>

          <Route exact path='/pricing'>
            <Pricing />
          </Route>

          <Route exact path='/contact'>
            <Contact />
          </Route>

          <Route exact path='/privacy'>
            <Privacy />
          </Route>

          <Route exact path='/terms'>
            <Terms />
          </Route>

          {/* TODO: 404 page */}
          <Route path='*'>
            <NotFound />
          </Route>
        </Switch>
      </>
    </Router>
  );
};

export default App;
