import React from 'react';

import styles from './Input.module.scss';

interface InputProps {
  name: string;
  placeholder?: string;
  value: string;
  type?: string;
  handleChange: (fieldName: string, value: string) => void;
  multiline?: boolean;
  required?: boolean;
}

const Input: React.FC<InputProps> = ({
  name,
  placeholder,
  value,
  handleChange,
  multiline = false,
  ...props
}) => {
  if (multiline) {
    return (
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange(name, e.target.value)}
        rows={6}
        className={styles.formInput}
        {...props} />
    );
  }

  return (
    <input
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={(e) => handleChange(name, e.target.value)}
      className={styles.formInput}
      {...props} />
  );
};

export default Input;