import React from 'react';
import { Link } from 'react-router-dom';

import {
  Header,
  Footer,
  Section,
  SectionNudge,
  IconBlock,
  PricingSectionContainer,
  HeaderBlurb
} from 'components';

import {
  essentialsIcon,
  businessIcon,
  enterpriseIcon,
  privateIcon,
  documentIcon
} from 'assets/icons';

import { Align, Span } from 'types/enums';

import styles from './Pricing.module.scss';

const Pricing: React.FC = () => {
  return (
    <>
      <Header
        alignment={Align.Center}
        topBarButtonText='Try free today'>
        <h1>Get started for <strong>free</strong> while we’re in <strong>beta</strong></h1>

        <HeaderBlurb className={`${styles.pricingBlurb} left`}>
          We are still defining our pricing model with the intent of making it as flexible as possible. We&apos;ve outlined the plans we intend to offer, if you have any feedback <Link to='/contact' className='link'>please reach out</Link>.
        </HeaderBlurb>

        <SectionNudge className={styles.pricingPlanNudge} text='Pricing plans' />
      </Header>

      <main>
        <PricingSectionContainer>
          <div className={`${styles.pricingBackground} sectionBackground left`}></div>
          <h2 className='half'>A range of pricing options<br />to suit your business</h2>

          <ul className='iconsList'>
            <li>
              <IconBlock
                title='Essentials'
                subTitle='30 documents / mo'
                img={essentialsIcon}>
                <p>Create templates and generate up to 30 documents a month letting you simplify your business workflow.</p>
              </IconBlock>
            </li>
            <li>
              <IconBlock
                title='Business'
                subTitle='200 documents / mo'
                img={businessIcon}>
                <p>Create documents at scale, send up to 200 forms or integrate it into your existing form&apos;s technology to take full advantage of our product.</p>
              </IconBlock>
            </li>
            <li>
              <IconBlock
                title='Enterprise'
                subTitle='200+ documents / mo'
                img={enterpriseIcon}>
                <p>A platform you can depend on to deliver documents reliably, quickly and at scale. Get in touch to discuss a plan that works for your business.</p>
              </IconBlock>
            </li>
            <li>
              <IconBlock
                title='Private'
                img={privateIcon}>
                <p>For some organisation who have a high priority on privacy and performance; we will also be offering a private cloud plan.</p>
                <p>You will have your own dedicated servers and database helping maximize privacy and performance.</p>
              </IconBlock>
            </li>
          </ul>

          <SectionNudge
            className={styles.controlNudge}
            text='Want more control?'
            alignment={Align.Right} />
        </PricingSectionContainer>

        <Section
          alignment={Align.Left}
          span={Span.Split}>
          <div className={styles.inlineIconBlock}>
            <img
              src={documentIcon}
              alt='Custom' />

            <div className={styles.content}>
              <h3>Take control</h3>
              <h2>Licensing will give you all the control you need</h2>
              <p>We are also exploring a licensing model which will allow you to deploy our product on your own infrastructure giving you even more control of your data.</p>
              <p>We will help set-up and maintain the product and all you need to pay is a yearly licensing fee.</p>
              <p>This is perfect for large companies and government bodies where data privacy is vital and you have strict data sovereignty requirements.</p>
            </div>
          </div>
        </Section>
      </main>

      <Footer />
    </>
  );
};

export default Pricing;