import React from 'react';

import styles from './PricingSectionContainer.module.scss';

interface Props {
  className?: string;

}

const PricingSectionContainer: React.FC<Props> = ({ className, children }) => (
  <section className={`${className ? className : ''} ${styles.pricingSection}`}>
    {children}
  </section>
);

export default PricingSectionContainer;