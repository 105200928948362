import React from 'react';

import styles from './FormInput.module.scss';

interface FormInputProps {
  title: string;
  helpText: string;
}

const FormInput: React.FC<FormInputProps> = ({ children, title, helpText }) => {
  return (
    <div className={styles.formGroup}>
      <h3>{title}</h3>
      <p>{helpText}</p>
      {children}
    </div>
  );
};

export default FormInput;