import React from 'react';
import { Link } from 'react-router-dom';

import styles from './CustomLink.module.scss';

interface Props {
  type: 'primary' | 'secondary';
  className?: string;
  label: string;
  link: string;
  icon?: React.ReactNode;
}

const CustomLink: React.FC<Props> = ({ type, className, label, link, icon }) => (
  <Link className={`${styles.customLinkButton} ${type === 'secondary' ? styles.secondary : styles.primary} ${className ? className : ''}`} to={link}>
    {label}
    {icon ?? null}
  </Link>
);

export default CustomLink;
