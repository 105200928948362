import React from 'react';
import { Link } from 'react-router-dom';

//import { Icon } from 'components';
import { Align, Span } from 'types/enums';

import styles from './Header.module.scss';

import { logo } from 'assets/images';

interface HeaderProps {
  alignment?: Align;
  span?: Span;
  topBarButtonText: string;
}

const Header: React.FC<HeaderProps> = ({ children, alignment = Align.Left, span = Span.Full/*, topBarButtonText*/ }) => {
  let rootClasses = '';
  let contClasses = styles.content;

  switch (alignment) {
    case Align.Center:
      rootClasses += ' center';
      break;
    case Align.Right:
      rootClasses += ' right';
      break;
  }

  switch (span) {
    case Span.Full:
      contClasses += ' fullSection';
      break;
    case Span.Split:
      contClasses += ' splitSection';
      break;
  }

  return (
    <header className={rootClasses.trim()}>
      <div className={styles.topBar}>
        <Link to='/'>
          <img
            className={styles.logo}
            src={logo}
            alt='whtdoc' />
        </Link>

        {/* TODO: update login link */}
        {/*<a className='primary' href='#'>{topBarButtonText} <Icon img={linkIcon} /></a>*/}
      </div>

      <div className={contClasses.trim()}>
        {children}
      </div>
    </header>
  );
};

export default Header;