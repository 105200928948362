import React from 'react';

import styles from './Icon.module.scss';

interface IconProps {
  img: string;
}

const Icon: React.FC<IconProps> = ({ img }) => {
  return (
    <img
      className={styles.icon}
      src={img} />
  );
};

export default Icon;