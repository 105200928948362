import React from 'react';

import { Align, Span } from 'types/enums';
import './Section.module.scss';

interface SectionProps {
  className?: string;
  alignment?: Align;
  span?: Span;
}

const Section: React.FC<SectionProps> = ({ className, children, alignment = Align.Left, span = Span.Full }) => {
  let rootClasses = '';
  let contClasses = '';

  if (className) {
    rootClasses += className;
  }

  switch (alignment) {
    case Align.Center:
      rootClasses += ' center';
      break;
    case Align.Right:
      rootClasses += ' right';
      break;
  }

  switch (span) {
    case Span.Full:
      contClasses += ' fullSection';
      break;
    case Span.Split:
      contClasses += ' splitSection';
      break;
  }

  return (
    <section className={rootClasses.trim()}>
      <div className={contClasses.trim()}>
        {children}
      </div>
    </section>
  );
};

export default Section;