import React from 'react';
import { Link } from 'react-router-dom';

import { Header, Footer, Section, SectionNudge, HeaderBlurb } from 'components';

import { setup01, setup02, setup03 } from 'assets/images';
import { Align, Span } from 'types/enums';
import styles from './GettingStarted.module.scss';

const GettingStarted: React.FC = () => {
  return (
    <>
      <Header
        alignment={Align.Center}
        topBarButtonText='Try free today'>
        <h1>How can you <strong>get setup</strong> and start creating documents?</h1>

        <HeaderBlurb className={`${styles.pricingBlurb} left`}>
          To get started creating documents simply sign-up and follow the simple steps below.
        </HeaderBlurb>

        <SectionNudge
          className={styles.headingNudge}
          text='Let&apos;s get started' />
      </Header>

      <main>
        {/* STEP ONE */}
        <div className={`${styles.row} ${styles.stepRow}`}>
          <div className={`${styles.gettingStartedBackground} sectionBackground left`}></div>

          <div className={styles.column30}>
            <div className={styles.content}>
              <h3>Step 1</h3>
              <h2>Setup a template</h2>
              <p>Open the templates page and hit the add button to start designing your document.</p>
              <p>Choose from a range of field types available in our simple template builder.</p>
              <p>We&apos;ll be adding more based on feedback so reach out if there&apos;s something that would be useful to you.</p>
            </div>
          </div>

          <div className={`${styles.column70} ${styles.image}`}>
            <img
              src={setup01}
              alt='Create a template using the simple drag and drop builder.' />
          </div>

          <SectionNudge
            className={styles.stepsNudge}
            text='A bit of config'
            alignment={Align.Right} />
        </div>

        {/* STEP TWO */}
        <div className={`${styles.row} ${styles.stepRow}`}>
          <div className={`${styles.column70} ${styles.image}`}>
            <img
              src={setup02}
              alt='Setup an application to configure your integration.' />
          </div>

          <div className={styles.column30}>
            <div className={styles.content}>
              <h3>Step 2</h3>
              <h2>Configure your integration</h2>
              <p>Open the applications page and hit the add button to configure your integration options.</p>
              <p>Simply choose your applications input and output settings and we’ll setup what you need to start creating documents.</p>
              <p>It’s possible to configure multiple applications, but for most businesses one is enough. <Link to='/contact'>Reach out</Link> if you need help configuring your integration.</p>
            </div>
          </div>

          <SectionNudge
            className={styles.stepsNudge}
            text='A bit of config'
            alignment={Align.Right} />
        </div>

        {/* STEP THREE */}
        <div className={`${styles.row} ${styles.stepRow}`}>
          <div className={styles.column30}>
            <div className={styles.content}>
              <h3>Step 3</h3>
              <h2>Send form to customers</h2>
              <p>For API integrations <Link to='/api-setup'>skip this step</Link>.</p>
              <p>Simply select Form submissions from the menu then hit the Send icon next to your application.</p>
              <p>Remember only applications you&apos;ve configured with the Form input will be available in this list.</p>
              <p>Choose a template and send a request for your customers to complete the form. </p>
            </div>
          </div>

          <div className={`${styles.column70} ${styles.image}`}>
            <img
              src={setup03}
              alt='Send your document out for user completion.' />
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default GettingStarted;