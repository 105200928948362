import React, { useEffect } from 'react';
import Select from 'react-select';

interface SelectInputProps {
  name: string;
  value?: string;
  options: Array<SelectInputOption>;
  placeholder: string;
  handleChange: (fieldName: string, value?: string) => void;
  required?: boolean;
}

interface SelectInputOption {
  value: string;
  label: string;
}

const SelectInput: React.FC<SelectInputProps> = ({ name, options, value, placeholder, handleChange, ...props }) => {
  useEffect(() => {
    // Set default value into form data
    if (options && options.length > 0) {
      handleChange(name, options[0].value);
    }
  }, []);

  // Retrieve selected value from options
  const selectedValue = options.find(option => option.value === value);

  return (
    <Select
      name={name}
      defaultValue={options[0]}
      value={selectedValue}
      placeholder={placeholder}
      options={options}
      onChange={selectedOption => {
        // Multi-select not required
        if (Array.isArray(selectedOption)) {
          throw new Error('Unexpected type passed to ReactSelect onChange handler');
        }

        handleChange(name, selectedOption?.value);
      }}
      isSearchable={false}
      styles={{
        menu:  styles => ({
          ...styles,
          marginTop: '1px'
        }),
        control: (styles, { isFocused }) => ({
          ...styles,
          borderRadius: '7px',
          padding: '1px 4px',
          border: '1px solid',
          outline: 'none',
          borderColor: isFocused ? '#0C69E3 !important' : 'rgba(0, 0, 0, 0.1) !important',
          boxShadow: isFocused ? '1px 1px 3px rgba(0, 0, 0, 0.1)' : 'none',
          cursor: 'pointer'
        }),
        option: (styles, { isSelected }) => {
          return {
            ...styles,
            cursor: 'pointer',
            background: isSelected ? '#0C69E3' : styles.background,
            padding: '10px 15px'
          };
        }
      }}
      {...props}
    />
  );
};

export default SelectInput;