import React from 'react';

import styles from './HeaderBlurb.module.scss';

interface Props {
  className?: string;
}

const HeaderBlurb: React.FC<Props> = ({ className, children }) => (
  <p className={`${className ? className : ''} ${styles.headerTextBlurb}`}>
    {children}
  </p>
);

export default HeaderBlurb;
